import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import Cropper from 'cropperjs';
import track from '../track';
import api from '../api';
import countryFuzzySearch from '../util/fuzzy-search';
import strengthMeter from '../strength_meter';
import { clickWithDataMethod } from './ujs';

Alpine.plugin(focus);
Alpine.magic('track', () => track);
Alpine.magic('api', () => api);
Alpine.magic('clickWithDataMethod', () => clickWithDataMethod);
Alpine.magic('focus', () => focus);
Alpine.magic('countryFuzzySearch', () => countryFuzzySearch);
Alpine.magic('strengthMeter', () => strengthMeter);
Alpine.magic('Cropper', () => Cropper);

require('packs/webtour.js');

export default Alpine;
