

function isInternalLink(elem) {
  return elem.matches(`a[href^="${window.location.origin}"], a[href]:not([href^="https://"], [href^="http://"])`);
}

/**
 * @param event {Event}
 */
 function inferParams(event) {
  if (!event) return {};
  if (event.target.matches(['input', 'textarea'])) {
    return {
      event: 'form',
      action: 'type',
      name: 'onclick',
      type: 'click',
    };
  }
  if (event.currentTarget?.matches('a')) {
    return {
      event: 'link',
      action: 'click',
      name: 'onclick',
      type: isInternalLink(event.currentTarget) ? 'internal link' : 'external link',
      text: event.currentTarget.innerText,
    };
  }
  if (event.currentTarget?.matches(['button', 'select'])) {
    return {
      event: 'select',
      text: event.currentTarget.innerText,
      type: 'click',
      action: 'click',
      name: 'onclick',
    };
  }
  if (event.currentTarget?.matches('form')) {
    const input = event.currentTarget.querySelector('input[type]');
    return {
      event: 'search',
      action: 'type',
      name: 'onenter',
      text: input && input.value,
    };
  }
  return {};
}

function gaTrack(params) {
  const args = [
    'send', 'event', params.gaTag,
    params.action, params.loginMethodKey, params.text,
    params.type === 'external link' ? { transport: 'beacon' } : null,
    params.setting,
  ].filter(Boolean);
  if (process.env.NODE_ENV === 'development') {
    console.log('ga', args);
    if (!params.gaTag) {
      console.warn('sending ga event with no explicit tag!', params);
    }
  }
  if (!window.ga) {
    console.log('skipping ga notification. window.ga was not found');
    return;
  }
  window.ga(...args);
}

function gtmTrack(params) {
  if (process.env.NODE_ENV === 'development') {
    console.log('gtm push', params);
  }
  if (!window.dataLayer) {
    console.log('skipping gtm notification, window.dataLayer was not found');
    return;
  }
  window.dataLayer.push(params);
}

// We have a bunch of x-effect expressions that fire once when the page loads.
// We don't want those to report that the user clicked *everything* immediately
// when the page loaded, so we'll wait a bit before enabling this function.
let analyticsEnabled = false;
setTimeout(() => { analyticsEnabled = true; }, 20);

const GTM_PAGES = ['/', '/faq'];
export default function analyticsTrack(params, event) {
  if (!analyticsEnabled) return;

  const dataCombination = { ...inferParams(event), ...params };
  if (dataCombination.text) { dataCombination.text = dataCombination.text.toLowerCase(); }
  if (dataCombination.section) { dataCombination.section = dataCombination.section.toLowerCase(); }
  if (dataCombination.component) { dataCombination.component = dataCombination.component.toLowerCase(); }
  if (GTM_PAGES.includes(window.location.pathname) || window.FeatureFlag['scholars_gtm_on?']) {
    gtmTrack(dataCombination);
  } else {
    gaTrack(dataCombination);
  }
}
