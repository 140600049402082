// set this on the window, so it's accessible for capybara
window.currentlyActiveRequests = 0;

export function countingActiveRequests(action) {
  window.currentlyActiveRequests += 1;
  return action()
    .then((x) => {
      window.currentlyActiveRequests -= 1;
      return x;
    })
    .catch((x) => {
      window.currentlyActiveRequests -= 1;
      throw x;
    });
}

export default () => window.currentlyActiveRequests;
