//= require js-cookie/src/js.cookie
//= require shepherd.js/dist/js/shepherd
import Cookies from "js-cookie";
import Shepherd from "shepherd.js";
window.Shepherd = Shepherd;
window.Cookies = Cookies;

let Webtour = new Object();
Webtour.tours = {};
Webtour.incompleteTours = [];

window.Webtour = Webtour;
let tries = 0;

const startCheck = setInterval(function() 
{
  if (Webtour.incompleteTours.length) {
    clearInterval(startCheck);
    Webtour.tours[Webtour.incompleteTours[0]].checkAndStart();
  } else {
    tries += 1;
    if (tries > 200) {
      clearInterval(startCheck);
    }
  }
},100);


