/**
 * This file contains a reimplementation of those parts of
 * jquery-ujs that we're actually using at this point.
 * just data-method
 *
 * We're not trying to expand it. Ideally, it will go away.
 * Largely based on https://github.com/hauleth/vanilla-ujs/blob/master/lib/assets/javascripts/vanilla-ujs/method.js
 */

import ready from './ready';
import * as csrf from './csrf';
import matchesSelfOrAncestor from './matches-self-or-ancestor';

export function clickWithDataMethod(event) {
  // Only left click allowed. Firefox triggers click event on right click/contextmenu.
  if (event.button !== 0) {
    return;
  }
  const element = matchesSelfOrAncestor(event.target, 'a[data-method]');
  if (element) {
    const url = element.getAttribute('href');
    const method = element.getAttribute('data-method').toUpperCase();
    const data = {};

    if (csrf.param() && csrf.token()) {
      data[csrf.param()] = csrf.token();
    }

    if (submit({ url, method, data, target: element })) {
      event.preventDefault();
    } else {
      return true;
    }
  }

  function submit(options) {
    if (options.method === 'GET') {
      return false;
    }

    const form = document.createElement('form');
    form.method = 'POST';
    form.action = options.url;
    form.style.display = 'none';

    Object.keys(options.data).forEach((param) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', param);
      input.setAttribute('value', options.data[param]);
      form.appendChild(input);
    });

    if (options.method !== 'POST') {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', '_method');
      input.setAttribute('value', options.method);
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
    return true;
  }
}

export default () => ready(() => document.addEventListener('click', clickWithDataMethod, false));
