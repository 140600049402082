import { PasswordMeter } from 'password-meter';

export default function strengthMeter({ password }) {
  const passMeter = new PasswordMeter();

  const result = passMeter.getResult(password);
  const strength = result.percent;

  const strengthBar = document.getElementById('strength-bar');
  strengthBar.value = strength;
}
