/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import Alpine from '../app/entry-setup/alpine-setup';

function landingPageData() {
  return {
    index: 0,
    carouselIndices: { media: 0, testimonial: 0 },
    modalIsOpen: false,
    channel: 'youtube',
    url: null,
    videoId: null,
    title: null,
    author: null,
    image: null,
    body: null,
    youtube: {
      autoplay: 1,
      cc_load_policy: 1,
      color: null,
      controls: 1,
      disablekb: 0,
      enablejsapi: 0,
      end: null,
      fs: 1,
      h1: null,
      iv_load_policy: 1,
      list: null,
      listType: null,
      loop: 0,
      modestbranding: null,
      origin: null,
      playlist: null,
      playsinline: null,
      rel: 0,
      showinfo: 1,
      start: 0,
      wmode: 'transparent',
      theme: 'dark',
      mute: 0,
    },
    ratio: '16:9',
    vimeo: {
      api: false,
      autopause: true,
      autoplay: true,
      byline: true,
      callback: null,
      color: null,
      height: null,
      loop: false,
      maxheight: null,
      maxwidth: null,
      player_id: null,
      portrait: true,
      title: true,
      width: null,
      xhtml: false,
    },
    youku: {
      autoplay: 1,
      show_related: 0,
    },
    allowFullScreen: true,
    animationSpeed: 300,
    showDescription: true,
    renderPageIndicators(pages, styleFor) {
      let buttons = '';
      for (let i = 1; i <= pages; i++) {
        if (i === this.carouselIndices[styleFor] + 1) {
          buttons += `<div key=${i} class="landing-page-carousel__active-page-indicator"></div>`;
        } else {
          buttons += `<div key=${i} class="landing-page-carousel__inactive-page-indicator"></div>`;
        }
      }
      return buttons;
    },
    toggleHideDescription(number, text) {
      const buttonElement = document.getElementById(`opportunity-content-trigger-${number}`);
      const containerElement = document.getElementById(`opportunity-content-container-${number}`);
      let action = 'open';
      if (buttonElement.getAttribute('aria-expanded') === 'true') {
        action = 'close';
        buttonElement.setAttribute('aria-expanded', 'false');
        buttonElement.classList.add('landing-page-opportunities__accordion-expanded-exit-done');
        buttonElement.classList.remove('landing-page-opportunities__accordion-expanded-enter-done');

        containerElement.setAttribute('x-show.transition.duration.500ms', 'false');
        containerElement.setAttribute('aria-hidden', 'true');
        containerElement.classList.add('landing-page-opportunities__opportunity-description-exit-done');
        containerElement.classList.remove('landing-page-opportunities__opportunity-description-enter-done');
      } else {
        buttonElement.setAttribute('aria-expanded', 'true');
        buttonElement.classList.add('landing-page-opportunities__accordion-expanded-enter-done');
        buttonElement.classList.remove('landing-page-opportunities__accordion-expanded-exit-done');

        containerElement.setAttribute('x-show.transition.duration.500ms', 'true');
        containerElement.setAttribute('aria-hidden', 'false');
        containerElement.classList.add('landing-page-opportunities__opportunity-description-enter-done');
        containerElement.classList.remove('landing-page-opportunities__opportunity-description-exit-done');
      }
      this.$track({ event: 'collapse', action, name: 'onclick', type: 'click', region: 'main content', section: 'baobab opportunities', text });
    },
    scrollLeft(pages, styleFor) {
      const section = styleFor === 'media' ? 'Explore Baobab community blogs, podcasts, and videos.' : 'baobab ambassadors';
      this.$track({ event: 'select', action: 'click', name: 'onclick', type: 'carousel', region: 'main content', section, text: 'left chevron' });
      if (this.carouselIndices[styleFor] === 0) {
        this.carouselIndices[styleFor] = pages - 1;
      } else {
        this.carouselIndices[styleFor] -= 1;
      }
    },
    scrollRight(pages, styleFor) {
      const section = styleFor === 'media' ? 'Explore Baobab community blogs, podcasts, and videos.' : 'baobab ambassadors';
      this.$track({ event: 'select', action: 'click', name: 'onclick', type: 'carousel', region: 'main content', section, text: 'right chevron' });
      if (this.carouselIndices[styleFor] === pages - 1) {
        this.carouselIndices[styleFor] = 0;
      } else {
        this.carouselIndices[styleFor] += 1;
      }
    },
    checkScroll(event, tilesPerPage, pages, styleFor) {
      const focusedTile = event.target.closest('.carousel-item__media-item-container');
      const carousel = focusedTile && focusedTile.closest('.landing-page-carousel');
      const viewport = carousel && carousel.closest('.landing-page-carousel__viewport');
      if (!viewport || getComputedStyle(viewport).overflowX === 'scroll') {
        // Mobile or someone forgot to update the selectors above.
        return;
      }

      const focusedIndex = Array.from(carousel.children).indexOf(focusedTile) + 1;
      const lastIndexOnPage = tilesPerPage * (this.carouselIndices[styleFor] + 1);
      if (focusedIndex > lastIndexOnPage) {
        this.scrollRight(pages, styleFor);
      } else if (focusedIndex < (lastIndexOnPage - tilesPerPage + 1)) {
        this.scrollLeft(pages, styleFor);
      }
    },
    updateFocus(event, modalRef, windowX, windowY) {
      if (modalRef && !modalRef.contains(event.target)) {
        modalRef.focus();
        window.scrollTo(windowX, windowY);
      }
    },
    componentDidUpdate(element) {
      if (this.modalIsOpen && element) {
        element.focus();
        document.body.classList.add('Body__modal-open');
      } else {
        document.body.classList.remove('Body__modal-open');
        if (this.originalFocus && this.originalFocus !== document.body) {
          this.originalFocus.focus();
        }
      }
    },
    getQueryString(obj) {
      let url = '';
      Object.entries(obj).forEach(([key, value]) => {
        if (value !== null) {
          url += `${key}=${value}&`;
        }
      });
      return url.substr(0, url.length - 1);
    },
    getYoutubeUrl() {
      const query = this.getQueryString(this.youtube);
      return `//www.youtube.com/embed/${this.videoId}?${query}`;
    },
    getVimeoUrl(videoId) {
      const query = this.getQueryString(this.vimeo);
      return `//player.vimeo.com/video/${this.videoId}?${query}`;
    },
    getYoukuUrl() {
      const query = this.getQueryString(this.youku);
      return `//player.youku.com/embed/${this.videoId}?${query}`;
    },
    getVideoUrl() {
      let url = '';
      if (this.channel === 'youtube') {
        url = this.getYoutubeUrl(this.videoId);
      } else if (this.channel === 'vimeo') {
        url = this.getVimeoUrl(this.videoId);
      } else if (this.channel === 'youku') {
        url = this.getYoukuUrl(this.videoId);
      } else if (this.channel === 'custom') {
        url = this.url;
      }
      return url;
    },
    getPadding() {
      const arr = this.ratio.split(':');
      const width = Number(arr[0]);
      const height = Number(arr[1]);
      const padding = height * 100 / width;
      return `${padding}%`;
    },
    openModal(mediaType, link, title, author, image, body) {
      if (title === 'how can baobab help me?') {
        this.$track({ event: 'modal', action: 'open', name: 'onclick', type: 'click', region: 'main content', section: title, text: 'play button' });
      } else {
        this.$track({ event: 'modal', action: 'open', name: 'onclick', type: 'click', region: 'main content', section: 'explore baobab community blogs, podcasts, and videos.', text: title });
      }
      this.originalFocus = document.activeElement;
      this.windowX = window.scrollX;
      this.windowY = window.scrollY;
      if (mediaType === 'video') {
        this.channel = 'youtube';
        this.modalIsOpen = true;
        this.title = title;
        this.videoId = link;
      } else if (mediaType === 'podcast') {
        this.channel = 'podcast';
        this.modalIsOpen = true;
        this.videoId = link;
        this.title = title;
        this.author = author;
        this.image = image;
        this.body = body;
      } else if (mediaType === 'article') {
        this.channel = 'article';
        this.modalIsOpen = true;
        this.title = title;
        this.author = author;
        this.image = image;
        this.body = body;
      }
    },
    closeModal() {
      this.$track({ event: 'modal', action: 'close', name: 'onclick', type: 'click', region: 'main content', section: this.title, text: 'close cross' });
      this.channel = 'youtube';
      this.modalIsOpen = false;
      this.videoId = null;
      this.title = null;
      this.author = null;
      this.image = null;
      this.body = null;
      this.windowX = null;
      this.windowY = null;
      this.originalFocus = null;
    },
    scroll: { featureCards: 0, mediaCarousel: 0, testimonialCarousel: 0 },
    handleScroll(scrollFor, section, text) {
      if (this.scroll[scrollFor] === 0) {
        this.$track({ region: 'main content', action: 'scroll', section, text });
        this.scroll[scrollFor] = 1;
      }
    },
  };
}

Alpine.data('landingPageData', landingPageData);
Alpine.start();
